import { IQuestion3 as IQuestionStep3 } from '../scholarship-ai/steps/step3';
import { IQuestion4 as IQuestionStep4 } from '../scholarship-ai/steps/step4';
import { IQuestion5 as IQuestionStep5 } from '../scholarship-ai/steps/step5';

interface IQuestions3 {
  crypted_id: string;
  questions: IQuestionStep3[];
}

export const ICourseQuestions3: Array<IQuestions3> = [
  {
    crypted_id: '266374502a5a753721c702af302912c3',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Arquitetura de Software e Soluções',
          'Atuo com Arquitetura de Software e Soluções',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question:
          'Qual seu tempo de experiência com Arquitetura de Software e Soluções?',
        answers: [
          'Não tenho experiência com Arquitetura de Software e Soluções',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: 'c1eb62a5a6fe1cee1510d4a762aed3b7',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Arquitetura de Software e Soluções',
          'Atuo com Arquitetura de Software e Soluções',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question:
          'Qual seu tempo de experiência com Arquitetura de Software e Soluções?',
        answers: [
          'Não tenho experiência com Arquitetura de Software e Soluções',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: '3bdcad90177948febc61a7e338cc5d5e',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Data Science e Machine Learning',
          'Atuo com Data Science e Machine Learning',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question:
          'Qual seu tempo de experiência com Data Science e Machine Learning? ',
        answers: [
          'Não tenho experiência com Data Science e Machine Learning',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: '2e11947b190b235f5bec12b1db092b54',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Data Science e Machine Learning',
          'Atuo com Data Science e Machine Learning',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question:
          'Qual seu tempo de experiência com Data Science e Machine Learning? ',
        answers: [
          'Não tenho experiência com Data Science e Machine Learning',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: '46974fb5d883fd84cbfb563536d79c41',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Ciência de Dados para Mercado Financeiro',
          'Atuo com Ciência de Dados para Mercado Financeiro',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question:
          'Qual seu tempo de experiência com Ciência de Dados para Mercado Financeiro? ',
        answers: [
          'Não tenho experiência com Ciência de Dados para Mercado Financeiro',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: '3169469855324b4b4e9bdb51e8700ae7',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Cloud Computing',
          'Atuo com Cloud Computing',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question: 'Qual seu tempo de experiência com Cloud Computing?',
        answers: [
          'Não tenho experiência com Cloud Computing',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: 'b9c47d5a8f8760f2cc0a22a4dd41901c',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Desenvolvimento Full Stack',
          'Atuo com Desenvolvimento Full Stack',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question:
          'Qual seu tempo de experiência com Desenvolvimento Full Stack?',
        answers: [
          'Não tenho experiência com Desenvolvimento Full Stack',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: 'abaf116764c5e0eeb6d40ec9842d6019',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Engenharia e Arquitetura de Dados',
          'Atuo com Engenharia e Arquitetura de Dados',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question:
          'Qual seu tempo de experiência com Engenharia e Arquitetura de Dados?',
        answers: [
          'Não tenho experiência com Engenharia e Arquitetura de Dados',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: '162a11b76fd9f291a8f46d26149e4bd7',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Engenharia e Arquitetura de Dados',
          'Atuo com Engenharia e Arquitetura de Dados',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question:
          'Qual seu tempo de experiência com Engenharia e Arquitetura de Dados?',
        answers: [
          'Não tenho experiência com Engenharia e Arquitetura de Dados',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: '07585d850ec6bd79a577df3870c63a67',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Gestão Ágil de Projetos',
          'Atuo com Gestão Ágil de Projetos',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question: 'Qual seu tempo de experiência com Gestão Ágil de Projetos?',
        answers: [
          'Não tenho experiência com Gestão Ágil de Projetos',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },

  {
    crypted_id: '2e14077c561d8e2ebb0aac0967671164',
    questions: [
      {
        question: 'Em que área atua?',
        answers: [
          'Atuo em área de tecnologia, mas não com Segurança Cibernética',
          'Atuo com Segurança Cibernética',
          'Atuo em outra área, fora de tecnologia',
          'Estou desempregado(a)',
          'Nunca trabalhei, busco o primeiro emprego',
        ],
        map: 'occupationArea',
      },
      {
        question: 'Qual seu tempo de experiência profissional?',
        answers: [
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'experienceTime',
      },
      {
        question: 'Qual seu tempo de experiência com Segurança Cibernética?',
        answers: [
          'Não tenho experiência com Segurança Cibernética',
          'Menos de 1 ano',
          '1 a 3 anos',
          '3 a 5 anos',
          '5 a 10 anos',
          'Mais de 10 anos',
        ],
        map: 'dataExperience',
      },
      {
        question: 'Qual senioridade do seu cargo atual?',
        answers: [
          'Estagiário',
          'Assistente / Auxiliar',
          'Analista',
          'Coordenador',
          'Especialista',
          'Gerente',
          'Diretor',
          'Consultor',
          'PJ',
          'Empreendedor',
        ],
        map: 'currentSeniority',
      },
      {
        question: 'Quantos funcionários tem na sua empresa atual?',
        answers: ['até 10', 'de 10 a 100', 'de 100 a 1.000', 'mais de 1.000'],
        map: 'companyEmployees',
      },
    ],
  },
];

interface IQuestions4 {
  crypted_id: string;
  questions: IQuestionStep4[];
}

export const ICourseQuestions4: Array<IQuestions4> = [
  {
    crypted_id: '266374502a5a753721c702af302912c3',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Arquitetura de Software e Soluções da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Arquitetura de Software e Soluções',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: 'c1eb62a5a6fe1cee1510d4a762aed3b7',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Arquitetura de Software e Soluções da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Arquitetura de Software e Soluções',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: '2e11947b190b235f5bec12b1db092b54',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Data Science e Machine Learning da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Data Science e Machine Learning',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: '3bdcad90177948febc61a7e338cc5d5e',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Data Science e Machine Learning da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Data Science e Machine Learning',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: '46974fb5d883fd84cbfb563536d79c41',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Dados para Mercado Financeiro da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Dados para Mercado Financeiro',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: '3169469855324b4b4e9bdb51e8700ae7',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Cloud Computing da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Cloud Computing',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: 'b9c47d5a8f8760f2cc0a22a4dd41901c',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Desenvolvimento Full Stack da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Desenvolvimento Full Stack',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: 'abaf116764c5e0eeb6d40ec9842d6019',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Engenharia e Arquitetura de Dados da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Engenharia e Arquitetura de Dados',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: '162a11b76fd9f291a8f46d26149e4bd7',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Engenharia e Arquitetura de Dados da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Engenharia e Arquitetura de Dados',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: '07585d850ec6bd79a577df3870c63a67',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Gestão Ágil de Projetos da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Gestão Ágil de Projetos',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },

  {
    crypted_id: '2e14077c561d8e2ebb0aac0967671164',
    questions: [
      {
        question:
          'Quais suas motivações para ingressar neste programa de pós-graduação em Segurança Cibernética da XPE?',
        answers: [
          'Transição de carreira para a área de tecnologia',
          'Transição de carreira dentro de tecnologia',
          'Ampliar meu conhecimento na área de Segurança Cibernética',
          'Melhorar minhas perspectivas de carreira, melhorar minha remuneração ou ser promovido',
          'Aproveitar oportunidades de networking com profissionais da área',
          'Aproximar do ecossistema XP Inc',
          'Participar de projetos práticos e desafiadores',
          'Contribuir para a diversidade e inclusão na área de tecnologia',
        ],
        map: 'motivations',
      },
    ],
  },
];

interface IQuestions5 {
  crypted_id: string;
  questions: IQuestionStep5[];
}

export const ICourseQuestions5: Array<IQuestions5> = [
  {
    crypted_id: '266374502a5a753721c702af302912c3',
    questions: [
      {
        question:
          'A seleção adequada de design patterns (padrões de projetos) e estilos arquiteturais é fundamental para o desenvolvimento de sistemas de software robustos e de alta qualidade. Considerando as opções abaixo, qual é a combinação mais indicada de um design pattern e um estilo arquitetural para sistemas nos quais os componentes individuais podem ser substituídos ou estendidos sem afetar a estrutura global do sistema?',
        answers: [
          'Design Pattern Adapter e Arquitetura Cliente-Servidor.',
          'Design Pattern Prototype e Arquitetura em Camadas (Layers).',
          'Design Pattern Observer e Arquitetura Orientada a Serviços (SOA).',
          'Design Pattern Strategy e Arquitetura de Microsserviços.',
        ],
        map: 'code1',
        code: ``,
      },
      {
        question:
          'A adoção de soluções em nuvem oferece diversos benefícios, como escalabilidade e flexibilidade. No entanto, a segurança dos dados e a escolha adequada da arquitetura são essenciais para garantir o sucesso e a integridade dessas soluções. Ao selecionar uma arquitetura para uma aplicação crítica de processamento de dados, armazenamento e análise em nuvem, qual abordagem abaixo seria a mais apropriada?',
        answers: [
          'Arquitetura P2P (Peer-to-Peer) para distribuição descentralizada e aleatória dos dados, não carecendo de medidas de segurança adicionais.',
          'Arquitetura Monolítica com uso de autenticação baseada apenas senhas complexas.',
          'Arquitetura de Microsserviços com comunicação entre os serviços por HTTP com SSL/TLS.',
          'Arquitetura Serverless com uso de autenticação multifator e armazenamento criptografado dos dados.',
        ],
        map: 'code2',
        code: ``,
      },
    ],
  },

  {
    crypted_id: 'c1eb62a5a6fe1cee1510d4a762aed3b7',
    questions: [
      {
        question:
          'A seleção adequada de design patterns (padrões de projetos) e estilos arquiteturais é fundamental para o desenvolvimento de sistemas de software robustos e de alta qualidade. Considerando as opções abaixo, qual é a combinação mais indicada de um design pattern e um estilo arquitetural para sistemas nos quais os componentes individuais podem ser substituídos ou estendidos sem afetar a estrutura global do sistema?',
        answers: [
          'Design Pattern Adapter e Arquitetura Cliente-Servidor.',
          'Design Pattern Prototype e Arquitetura em Camadas (Layers).',
          'Design Pattern Observer e Arquitetura Orientada a Serviços (SOA).',
          'Design Pattern Strategy e Arquitetura de Microsserviços.',
        ],
        map: 'code1',
        code: ``,
      },
      {
        question:
          'A adoção de soluções em nuvem oferece diversos benefícios, como escalabilidade e flexibilidade. No entanto, a segurança dos dados e a escolha adequada da arquitetura são essenciais para garantir o sucesso e a integridade dessas soluções. Ao selecionar uma arquitetura para uma aplicação crítica de processamento de dados, armazenamento e análise em nuvem, qual abordagem abaixo seria a mais apropriada?',
        answers: [
          'Arquitetura P2P (Peer-to-Peer) para distribuição descentralizada e aleatória dos dados, não carecendo de medidas de segurança adicionais.',
          'Arquitetura Monolítica com uso de autenticação baseada apenas senhas complexas.',
          'Arquitetura de Microsserviços com comunicação entre os serviços por HTTP com SSL/TLS.',
          'Arquitetura Serverless com uso de autenticação multifator e armazenamento criptografado dos dados.',
        ],
        map: 'code2',
        code: ``,
      },
    ],
  },

  {
    crypted_id: '2e11947b190b235f5bec12b1db092b54',
    questions: [
      {
        question:
          'Qual das seguintes afirmações é a mais precisa sobre o que o código abaixo implementa?',
        answers: [
          'O código calcula o sexto número na sequência de números primos.',
          'O código calcula a soma dos primeiros 6 números naturais.',
          'O código calcula o fatorial de 6.',
          'O código calcula o sexto número na sequência de Fibonacci.',
        ],
        map: 'code1',
        code: `def calc(n):
  if n <= 1:
    return n
  else:
    return calc(n-1) + calc(n-2)
    
print(calc(6))`,
      },
      {
        question:
          'Qual das seguintes afirmações é a mais precisa sobre o que o código abaixo implementa?',
        answers: [
          'O código está treinando um modelo de regressão RandomForest e avaliando sua precisão no conjunto de testes.',
          'O código está utilizando o modelo RandomForest para gerar predições, mas não está treinando o modelo.',
          'O código está dividindo o dataset Iris em conjunto de treino e teste, mas não está treinando nenhum modelo.',
          'O código está treinando um modelo de classificação RandomForest e avaliando sua precisão no conjunto de testes.',
        ],
        map: 'code2',
        code: `from sklearn.ensemble import RandomForestClassifier
from sklearn.model_selection import train_test_split
from sklearn.datasets import load_iris

X, y = load_iris(return_X_y=True)

X_train, X_test, y_train, y_test = train_test_split(
    X,
    y,
    test_size=0.3,
    random_state=42,
)

clf = RandomForestClassifier(random_state=42)
clf.fit(X_train, y_train)

print(clf.score(X_test, y_test))`,
      },
    ],
  },

  {
    crypted_id: '3bdcad90177948febc61a7e338cc5d5e',
    questions: [
      {
        question:
          'Qual das seguintes afirmações é a mais precisa sobre o que o código abaixo implementa?',
        answers: [
          'O código calcula o sexto número na sequência de números primos.',
          'O código calcula a soma dos primeiros 6 números naturais.',
          'O código calcula o fatorial de 6.',
          'O código calcula o sexto número na sequência de Fibonacci.',
        ],
        map: 'code1',
        code: `def calc(n):
  if n <= 1:
    return n
  else:
    return calc(n-1) + calc(n-2)
    
print(calc(6))`,
      },
      {
        question:
          'Qual das seguintes afirmações é a mais precisa sobre o que o código abaixo implementa?',
        answers: [
          'O código está treinando um modelo de regressão RandomForest e avaliando sua precisão no conjunto de testes.',
          'O código está utilizando o modelo RandomForest para gerar predições, mas não está treinando o modelo.',
          'O código está dividindo o dataset Iris em conjunto de treino e teste, mas não está treinando nenhum modelo.',
          'O código está treinando um modelo de classificação RandomForest e avaliando sua precisão no conjunto de testes.',
        ],
        map: 'code2',
        code: `from sklearn.ensemble import RandomForestClassifier
from sklearn.model_selection import train_test_split
from sklearn.datasets import load_iris

X, y = load_iris(return_X_y=True)

X_train, X_test, y_train, y_test = train_test_split(
    X,
    y,
    test_size=0.3,
    random_state=42,
)

clf = RandomForestClassifier(random_state=42)
clf.fit(X_train, y_train)

print(clf.score(X_test, y_test))`,
      },
    ],
  },

  {
    crypted_id: '46974fb5d883fd84cbfb563536d79c41',
    questions: [
      {
        question:
          'Atualmente umas das áreas da TI de maior crescimento estratégico junto as instituições financeiras é a Ciência de Dados.  Isto porque, permite com essas instituições compreendam melhor o seu público e fornecer produtos e serviços adequados. Diante deste contexto, qual é uma das principais aplicações da ciência de dados no mercado financeiro?',
        answers: [
          'Predição de eventos macroeconômicos.',
          'Automação de processos contábeis.',
          'Análise de sentimento de mercado.',
          'Desenvolvimento de modelos de precificação de ativos.',
        ],
        map: 'code1',
        code: ``,
      },
      {
        question:
          'Para um investidor, a tomada de decisões estratégicas de investimento, a avaliação de riscos e definição de estratégias de negociação, são pontos fundamentais. Neste contexto, o uso da Ciência de Dados pode oferecer uma grande contribuição para o investidor. Na sua visão, de acordo com as opções a seguir, como a ciência de dados pode contribuir estrategicamente para que um investidor possa avaliar riscos e definir estratégias de negociação junto ao mercado financeiro?',
        answers: [
          'Identificando padrões e tendências para tomada de decisões de investimento.',
          'Automatizando processos financeiros e gestão de custos operacionais.',
          'Melhorando a personalização dos serviços financeiros para os clientes.',
          'Aumentando a segurança e proteção dos dados financeiros.',
        ],
        map: 'code2',
        code: ``,
      },
    ],
  },

  {
    crypted_id: '3169469855324b4b4e9bdb51e8700ae7',
    questions: [
      {
        question:
          'Uma empresa está planejando uma solução no Microsoft Azure para armazenar uma variedade de tipos de dados, incluindo dados estruturados, não estruturados e semi-estruturados, a fim de atender às suas necessidades analíticas e de processamento. Qual serviço do Azure é mais apropriado para atender a essa demanda diversificada de dados?',
        answers: [
          'Azure Virtual Machines',
          'Azure Cosmos DB',
          'Azure SQL Database',
          'Azure Data Lake Storage',
        ],
        map: 'code1',
        code: ``,
      },
      {
        question:
          'Uma instituição financeira está desenvolvendo uma solução de análise de crédito na Amazon Web Services (AWS) para avaliar o risco de crédito de seus clientes. Eles precisam de uma infraestrutura escalável e segura para processar grandes volumes de dados de forma eficiente. Quais serviços da AWS seriam mais apropriados para essa solução?',
        answers: [
          'Amazon Polly, AWS Step Functions, AWS Lambda',
          'Amazon Glacier, Amazon Elastic Beanstalk, Amazon Elasticache',
          'Amazon S3, Amazon EC2, Amazon RDS',
          'Amazon DynamoDB, Amazon Redshift, Amazon Kinesis',
        ],
        map: 'code2',
        code: ``,
      },
    ],
  },

  {
    crypted_id: 'b9c47d5a8f8760f2cc0a22a4dd41901c',
    questions: [
      {
        question:
          'O mercado de desenvolvimento de software vem mantendo taxas de crescimento superiores às de  outros mercados nos últimos anos. Um dos motivos é a demanda crescente por parte das empresas por soluções inovadoras baseadas em Inteligência Artificial, Blockchain, ChatBots, Realidade Aumentada e Virtual, entre outras. Neste ambiente, o profissional Full Stack deve possuir um perfil mais generalista e deve ser capaz de atuar em projetos que envolvem tanto o desenvolvimento Front End, criando experiências únicas para o usuário final, quanto o desenvolvimento Back End, criando aplicações seguras e rápidas. Diante deste contexto, qual das habilidades abaixo pode ser considerada a mais importante para um profissional Full Stack?',
        answers: [
          'Desenvolvimento de interfaces de usuário que encantam o usuário final.',
          'Desenvolvimento de soluções em back end e modelagem de banco de dados.',
          'Desenvolvimento de API´s de integrações entre back e font ends.',
          'Desenvolvimento de soluções fim a fim com o foco no sucesso no sucesso do seu usuário final.',
        ],
        map: 'code1',
        code: ``,
      },
      {
        question:
          'O uso da inteligência artificial (I.A.) em projetos de desenvolvimento de software tem se tornado cada vez mais comum e muitos especialistas afirmam que a I.A. é a próxima fronteira da tecnologia, com potencial para transformar todas as áreas da sociedade, sendo ela a principal tecnologia da 4ª revolução industrial. O uso da I.A. em projetos de desenvolvimento de software já vem ocorrendo há algum tempo com o uso de soluções que aplicam aprendizagem de máquina, redes neurais e machine learning. Mais recentemente, o surgimento do CHATGPT tem utilizado um outro conceito conhecido como I.A. Generativa, capacidade de criar novas informações a partir de conjuntos de dados pré-existentes. Sabendo que um desenvolvedor Full Stack deve conhecer diversas tecnologias e linguagens de desenvolvimento, podemos afirmar que entre as aplicações abaixo a que mais “sugere” o uso da I.A. Generativa é:',
        answers: [
          'Criação de playlists a partir da análise do perfil do usuário final de uma aplicação de streaming de música.',
          'Sugestão de conteúdo em uma rede social a partir da análise do perfil do usuário.',
          'O uso de veículos aéreo não tripulado (VANT) em regiões geográficas desconhecidas.',
          'Chats conversacionais que criam experiências únicas para usuário final.',
        ],
        map: 'code2',
        code: ``,
      },
    ],
  },

  {
    crypted_id: 'abaf116764c5e0eeb6d40ec9842d6019',
    questions: [
      {
        question:
          'Em um projeto de banco de dados, as diferentes etapas de modelagem de dados envolvem abstrações e recursos específicos para criar uma representação eficaz dos requisitos de armazenamento de dados do sistema. \n\n\nEtapa de Modelagem de Dados: \n\n1. Projeto Conceitual: nesta etapa, o foco está na criação de um modelo de dados de alto nível, independente de tecnologia, representando as informações a serem armazenadas pelo sistema. \n\n2. Projeto Lógico: o projeto lógico traduz o modelo de dados conceitual em uma representação mais detalhada, de acordo com a abordagem de modelagem a ser utilização (relacional, não-relacional, hierárquica etc.), mas ainda independente do SGBD (Sistema Gerenciador de Banco de Dados) a ser utilizado. \n\n3. Projeto Físico: o projeto físico concentra-se na implementação prática do banco de dados, incluindo aspectos como a criação de índices para otimizar o desempenho, a definição de partições para gerenciar o armazenamento e a configuração de parâmetros físicos para otimização do armazenamento físico dos dados. \n\n\nRecursos: \n\nA. Tabelas, chaves primárias, chaves estrangeiras e relacionamentos. \n\nB. Diagramas de entidade-relacionamento (DER), entidades e atributos. \n\nC. Índices, partições e configurações de armazenamento físico. \n\n\nRelacione corretamente as etapas de modelagem de dados aos recursos utilizados em cada etapa:',
        answers: [
          '1-C, 2-B, 3-A.',
          '1-A, 2-B, 3-C.',
          '1-B, 2-C, 3-A.',
          '1-B, 2-A, 3-C.',
        ],
        map: 'code1',
        code: ``,
      },
      {
        question:
          'No universo dos dados, um dos processos cruciais para assegurar a qualidade dos dados e a conformidade com as regras de negócios é responsável por coletar dados de diversas fontes, transformá-los para garantir consistência e integridade, e, posteriormente, carregá-los em um repositório de dados. Além disso, durante essa etapa, erros são detectados e corrigidos, e regras de negócios são aplicadas para garantir que os dados atendam aos padrões estabelecidos. Qual é o nome desse processo?',
        answers: [
          'Data Mining.',
          'Data Warehouse (DW).',
          'Modern Data Warehouse (MDW).',
          'ETL (Extração, Transformação e Carga).',
        ],
        map: 'code2',
        code: ``,
      },
    ],
  },

  {
    crypted_id: '162a11b76fd9f291a8f46d26149e4bd7',
    questions: [
      {
        question:
          'Em um projeto de banco de dados, as diferentes etapas de modelagem de dados envolvem abstrações e recursos específicos para criar uma representação eficaz dos requisitos de armazenamento de dados do sistema. \n\n\nEtapa de Modelagem de Dados: \n\n1. Projeto Conceitual: nesta etapa, o foco está na criação de um modelo de dados de alto nível, independente de tecnologia, representando as informações a serem armazenadas pelo sistema. \n\n2. Projeto Lógico: o projeto lógico traduz o modelo de dados conceitual em uma representação mais detalhada, de acordo com a abordagem de modelagem a ser utilização (relacional, não-relacional, hierárquica etc.), mas ainda independente do SGBD (Sistema Gerenciador de Banco de Dados) a ser utilizado. \n\n3. Projeto Físico: o projeto físico concentra-se na implementação prática do banco de dados, incluindo aspectos como a criação de índices para otimizar o desempenho, a definição de partições para gerenciar o armazenamento e a configuração de parâmetros físicos para otimização do armazenamento físico dos dados. \n\n\nRecursos: \n\nA. Tabelas, chaves primárias, chaves estrangeiras e relacionamentos. \n\nB. Diagramas de entidade-relacionamento (DER), entidades e atributos. \n\nC. Índices, partições e configurações de armazenamento físico. \n\n\nRelacione corretamente as etapas de modelagem de dados aos recursos utilizados em cada etapa:',
        answers: [
          '1-C, 2-B, 3-A.',
          '1-A, 2-B, 3-C.',
          '1-B, 2-C, 3-A.',
          '1-B, 2-A, 3-C.',
        ],
        map: 'code1',
        code: ``,
      },
      {
        question:
          'No universo dos dados, um dos processos cruciais para assegurar a qualidade dos dados e a conformidade com as regras de negócios é responsável por coletar dados de diversas fontes, transformá-los para garantir consistência e integridade, e, posteriormente, carregá-los em um repositório de dados. Além disso, durante essa etapa, erros são detectados e corrigidos, e regras de negócios são aplicadas para garantir que os dados atendam aos padrões estabelecidos. Qual é o nome desse processo?',
        answers: [
          'Data Mining.',
          'Data Warehouse (DW).',
          'Modern Data Warehouse (MDW).',
          'ETL (Extração, Transformação e Carga).',
        ],
        map: 'code2',
        code: ``,
      },
    ],
  },

  {
    crypted_id: '07585d850ec6bd79a577df3870c63a67',
    questions: [
      {
        question:
          'O Scrum é o framework mais usado quando as empresas iniciam o uso de métodos ágeis.  Qual a alternativa abaixo não representa um componente do Scrum?',
        answers: [
          'Reunião diária.',
          'Sprint.',
          'Retrospectiva.',
          'Reabastecimento.',
          // 'Planning.',
        ],
        map: 'code1',
        code: ``,
      },
      {
        question:
          'Um dos grandes desafios da transformação ágil é expandir ou escalar as práticas na empresa respeitando as suas características e contexto.  Considerando que existem diversas práticas que contribuem com esse objetivo, qual a alternativa abaixo não representa um modelo ou framework que é usado para escalar o ágil?',
        answers: [
          'Nexus.',
          'Lean Startup.',
          'SAFe.',
          'LeSS.',
          // 'Disciplined Agile.',
        ],
        map: 'code2',
        code: ``,
      },
    ],
  },

  {
    crypted_id: '2e14077c561d8e2ebb0aac0967671164',
    questions: [
      {
        question:
          'Qual é a importância das medidas de proteção contra-ataques cibernéticos?',
        answers: [
          'Garantir a integridade e confidencialidade dos dados sensíveis da empresa.',
          'Minimizar os custos operacionais e aumentar a eficiência dos processos.',
          'Melhorar a experiência do cliente e a fidelidade à marca.',
          'Aumentar a competitividade no mercado e atrair investidores.',
        ],
        map: 'code1',
        code: ``,
      },
      {
        question: 'O que é privacidade de dados?',
        answers: [
          'É o direito de controlar e proteger as informações pessoais dos indivíduos.',
          'Refere-se à segurança das redes e sistemas de uma empresa.',
          'É a prática de compartilhar dados pessoais sem assinatura.',
          'Tratar a criptografia de dados para proteger os acessos não autorizados.',
        ],
        map: 'code2',
        code: ``,
      },
    ],
  },
];
