import logo from '../../images/logo-faculdade-xp.svg';
import styled from 'styled-components';

const HeaderElement = styled.header`
  /* background-color: red; */
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: max(1300px, 80vw); */
  margin-top: 10px;
  /* margin-bottom: 40px; */

  @media (min-width: 960px) {
    /* padding-top: 15px 0; */
    justify-content: flex-start;
  }
`;

const LogoImg = styled.img`
  height: 36px;
  /* background-color: yellow; */
`;

const RightDiv = styled.div`
  /* background-color: yellow; */
  // margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// const ButtonElement = styled(Button)`
//   height: 40px;
//   margin: 0 5px !important;

//   @media (max-width: 959px) {
//     display: none;
//   }
// `;

const HeaderCircled = () => {
  return (
    <HeaderElement>
      <LogoImg src={logo} alt="Logo XPe Multi" />
      <RightDiv>
        {/* <ButtonElement variant="contained">HOME</ButtonElement> */}
        {/* <ButtonElement variant="contained">AMBIENTE DE ENSINO</ButtonElement> */}
      </RightDiv>
    </HeaderElement>
  );
};

export default HeaderCircled;
