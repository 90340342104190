import {
  Button,
  FormControlLabel,
  Stepper,
  TextField,
  Alert,
  MenuItem,
  Select,
} from '@mui/material';
import styled from 'styled-components';
import SyntaxHighlighter from 'react-syntax-highlighter';

export const StyledStepper = styled(Stepper)`
  width: min(800px, 90vw) !important;

  & .MuiStepConnector-line {
    border-top-width: 2px;
    margin-top: -1px;
    width: 35px;
    margin: 0 auto;
  }

  & .Mui-completed {
    & .MuiStepConnector-line {
      border-color: #7ddd00;
    }

    border-color: white !important;
    color: white !important;
  }

  & .Mui-active {
    & .MuiStepConnector-line {
      border-color: #7ddd00;
    }

    border-color: #7ddd00 !important;
    color: #7ddd00 !important;
  }

  & .Mui-disabled {
    & .MuiStepConnector-line {
      border-color: rgba(200, 200, 200, 0.35);
    }

    border-color: white;
    color: rgba(200, 200, 200, 0.35);
  }

  & .MuiStepIcon-root {
    height: 45px !important;
    width: 45px !important;
  }

  & .MuiStepLabel-iconContainer {
    transform: scale(1.6);
  }

  span {
    font-family: 'Radio Canada', sans-serif !important;
    font-size: 16px;
  }
`;

export const MobileStepper = styled.div`
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;

  svg {
    color: #7ddd00;
    width: 40px !important;
    height: 40px !important;
  }

  p {
    margin-top: 10px;
    color: #7ddd00;
    font-size: 19px;
    text-align: center;
  }
`;

export const MainForm = styled.form`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  justify-content: space-between;
  align-items: center;
  width: min(900px, 90vw) !important;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
`;

export const LargeInput = styled(TextField)`
  width: min(600px, 90vw) !important;
  height: 48px !important;
  font-family: 'Radio Canada', sans-serif !important;

  & .MuiOutlinedInput-root {
    height: 60px !important;
    color: white !important;
    background-color: black !important;
    font-family: 'Radio Canada', sans-serif !important;

    & fieldset > legend {
      margin-left: 10px !important;
    }

    & fieldset {
      border-width: 1px !important;
      border-color: rgba(200, 200, 200, 0.25) !important;
      font-family: 'Radio Canada', sans-serif !important;
      border-radius: 10px !important;
    }
  }

  & .Mui-focused {
    height: 60px !important;
    color: rgba(255, 255, 255, 0.7) !important;
    font-family: 'Radio Canada', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  & .MuiInputLabel-root {
    height: 20px !important;
  }

  & input {
    color: white !important;
    margin-left: 5px !important;

    ::placeholder {
      opacity: 0.55 !important;
    }
  }

  label {
    margin-left: 10px;
  }
`;

export const ButtonsDiv = styled.div`
  width: min(600px, 90vw) !important;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  @media only screen and (min-width: 1200px) {
    margin-top: 10px;
  }
`;

export const ButtonsDivRadioForm = styled(ButtonsDiv)`
  width: min(850px, 90vw) !important;
  margin-top: -10px;

  @media only screen and (min-width: 1200px) {
    margin-top: 0;
  }
`;

export const ProgressButton = styled(Button)`
  background-color: #7ddd00 !important;
  width: 150px;
  height: 40px;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-family: 'Radio Canada', sans-serif !important;
  /* color: #766f01 !important; */
  color: rgb(18, 18, 18) !important;
  border-radius: 8px !important;

  &:hover {
    background-color: #cbbf00 !important;
  }

  svg {
    height: 16px !important;
  }

  @media only screen and (min-width: 1200px) {
    height: 50px;
    width: 165px;
  }
`;

export const BackButton = styled(Button)`
  width: 99px;
  height: 40px;
  /* color: #7ddd00 !important; */
  color: rgba(255, 255, 255, 0.8) !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-family: 'Radio Canada', sans-serif !important;
  border-radius: 8px !important;
  border-width: 2px !important;
  border-color: #7ddd00 !important;

  &:hover {
    background-color: #7ddd00 !important;
  }

  @media only screen and (min-width: 1200px) {
    height: 50px;
    width: 105px;
  }
`;

export const RadioGroupDiv = styled.div`
  width: min(850px, 90vw) !important;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: flex-start;

  img {
    max-width: 520px !important;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const RadioLabel = styled.label`
  width: min(850px, 90vw) !important;
  text-align: left !important;
  font-size: 16px;
  /* font-weight: bold; */
  display: inline-block;
  margin-bottom: 10px;
  white-space: pre-wrap;

  @media only screen and (min-width: 1200px) {
    font-size: 18px;
  }
`;

export const StyledFormLabel = styled(FormControlLabel)`
  height: 28px !important;
  padding-bottom: 7px;

  & .MuiRadio-root {
    /* color: white; */
    color: rgba(255, 255, 255, 0.6) !important;
    width: 24px !important;
    margin-left: 8px !important;

    @media only screen and (min-width: 1200px) {
      width: 28px !important;
    }
  }

  & .Mui-checked {
    color: #7ddd00 !important;
  }

  & .MuiTypography-root {
    color: rgba(255, 255, 255, 0.85);
    font-family: 'Radio Canada', sans-serif !important;
    font-size: 14px !important;
    line-height: 14px !important;

    @media only screen and (min-width: 1200px) {
      font-size: 16px !important;
    }
  }

  svg {
    width: 14px !important;

    @media only screen and (min-width: 1200px) {
      width: 16px !important;
    }
  }
`;

export const StyledDuolineFormLabel = styled(FormControlLabel)`
  height: 28px !important;
  padding-bottom: 7px;
  margin-top: 4px;

  & .MuiRadio-root {
    /* color: white; */
    color: rgba(255, 255, 255, 0.6) !important;
    width: 24px !important;
    margin-left: 8px !important;

    @media only screen and (min-width: 1200px) {
      width: 28px !important;
    }
  }

  & .Mui-checked {
    color: #7ddd00 !important;
  }

  & .MuiTypography-root {
    color: rgba(255, 255, 255, 0.85);
    font-family: 'Radio Canada', sans-serif !important;
    font-size: 14px !important;
    line-height: 14px !important;

    @media only screen and (min-width: 1200px) {
      font-size: 16px !important;
    }
  }

  svg {
    width: 14px !important;

    @media only screen and (min-width: 1200px) {
      width: 16px !important;
    }
  }
`;

export const StyledTrilineFormLabel = styled(FormControlLabel)`
  height: 28px !important;
  padding-bottom: 15px;
  margin-top: 15px;

  @media only screen and (min-width: 1200px) {
    height: 20px !important;
    padding-bottom: 10px;
    margin-top: 10px;
  }

  & .MuiRadio-root {
    /* color: white; */
    color: rgba(255, 255, 255, 0.6) !important;
    width: 24px !important;
    margin-left: 8px !important;

    @media only screen and (min-width: 1200px) {
      width: 28px !important;
    }
  }

  & .Mui-checked {
    color: #7ddd00 !important;
  }

  & .MuiTypography-root {
    color: rgba(255, 255, 255, 0.85);
    font-family: 'Radio Canada', sans-serif !important;
    font-size: 14px !important;
    line-height: 14px !important;

    @media only screen and (min-width: 1200px) {
      font-size: 16px !important;
      line-height: 18px !important;
    }
  }

  svg {
    width: 14px !important;

    @media only screen and (min-width: 1200px) {
      width: 16px !important;
    }
  }
`;

export const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  font-size: 14px;
  width: min(600px, 85vw) !important;
`;

export const StyledAlert = styled(Alert)`
  display: flex !important;
  align-items: center !important;
  width: min(870px, 86vw) !important;
  background-color: rgb(211, 92, 98) !important;
  font-family: 'Radio Canada', sans-serif !important;
  color: white !important;
  font-size: 14px !important;
  margin-left: -10px !important;
  margin-bottom: 10px;
  height: 24px !important;

  svg {
    color: white !important;
  }
`;

export const StyledInputAlert = styled(Alert)`
  display: flex !important;
  align-items: center !important;
  width: min(580px, 84vw) !important;
  background-color: rgb(211, 92, 98) !important;
  font-family: 'Radio Canada', sans-serif !important;
  color: white !important;
  font-size: 13px !important;
  /* margin-left: -10px !important; */
  margin-top: 5px !important;
  margin-bottom: -10px !important;
  height: 26px !important;

  svg {
    color: white !important;
  }

  @media only screen and (min-width: 1200px) {
    height: 24px !important;
    font-size: 14px !important;
  }
`;

interface alertMessageProps {
  alertMessage?: boolean;
}

export const SubSelectDiv = styled.div<alertMessageProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .MuiSelect-icon {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  & .select-area {
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => (props.alertMessage ? '20px' : '0')};
  }

  @media only screen and (min-width: 1400px) {
    padding: 5px 10px;
    min-height: 330px;
  }
`;

export const StyledSelect = styled(Select)`
  margin-top: 10px;
  width: min(600px, 90vw) !important;
  height: 60px !important;
  font-family: 'Radio Canada', sans-serif !important;
  border-radius: 10px !important;
  border-width: 1;

  &.MuiInputBase-root {
    border: 1px solid rgba(200, 200, 200, 0.25);
  }

  &.MuiOutlinedInput-root {
    color: white;

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgb(125, 221, 0);
    }
  }
` as typeof Select;

export const StyledLabel = styled.label``;

export const StyledMenuItem = styled(MenuItem)``;

export const LinkedInLabel = styled.label`
  font-size: 12px;
  /* font-style: italic; */
  width: min(600px, 85vw) !important;
  /* margin-left: 25px; */
  margin-top: -12px;
  line-height: 16px;
  text-align: justify;
  /* margin-bottom: -5px; */

  @media only screen and (min-width: 1200px) {
    font-size: 13px;
    margin-left: 20px;
    line-height: 17px;
    text-align: left;
  }
`;

export const CpfMessageLabel = styled.label`
  font-size: 12px;
  /* font-style: italic; */
  width: min(600px, 85vw) !important;
  /* margin-left: 25px; */
  margin-top: -12px;
  line-height: 16px;
  text-align: justify;
  margin-bottom: -5px;
  color: yellow !important;

  @media only screen and (min-width: 1200px) {
    font-size: 13px;
    margin-left: 20px;
    line-height: 17px;
    text-align: left;
  }
`;
