import { Step, StepLabel } from '@mui/material';
import HeaderCircled from '../../shared/page-structure/header-circled';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Step1Icon,
  Step1ProductIcon,
  Step2Icon,
  Step3Icon,
  Step4Icon,
  Step5Icon,
} from './components/icons';
import { StyledStepper, MobileStepper } from './components/shared-components';
import { Step1, Step2, Step3, Step4, Step5, Step1Product } from './steps';
import FooterBlack from '../../shared/page-structure/footer-black';
import { MainContainer } from '../../shared/page-structure/main-container';

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  font-family: 'Radio Canada', sans-serif !important;

  @media (min-width: 1200px) {
    padding-top: 80px;
    padding-bottom: 50px;
  }
`;

export interface ICompleteForm {
  name: string;
  email: string;
  cpf: string;
  phone: string;
  linkedin: string;
  ageGroup: string;
  genre: string;
  humanRace: string;
  educationLevel: string;
  highSchoolType: string;
  graduationType: string;
  occupationArea: string;
  experienceTime: string;
  dataExperience: string;
  currentSeniority: string;
  companyEmployees: string;
  motivations: string;
  code1: string;
  code2: string;
  selectedValue: string;
  crypted_id: string;
  course: string;
  courseExhibition: string;
}

export interface ITracker {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
}

export interface ICourses {
  crypted_id: string;
  name: string;
  exhibition_name: string;
}

const ScholarshipAi = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const [courses, setCourses] = useState<Array<ICourses>>([]);

  const [hasCourse, setHasCourse] = useState<boolean>(false);

  const [completeForm, setCompleteForm] = useState<ICompleteForm>({
    name: '',
    email: '',
    cpf: '',
    phone: '',
    linkedin: '',
    ageGroup: '',
    genre: '',
    humanRace: '',
    educationLevel: '',
    highSchoolType: '',
    graduationType: '',
    occupationArea: '',
    experienceTime: '',
    dataExperience: '',
    currentSeniority: '',
    companyEmployees: '',
    motivations: '',
    code1: '',
    code2: '',
    selectedValue: '',
    crypted_id: '',
    course: '',
    courseExhibition: '',
  });

  const [tracker, setTracker] = useState<ITracker>({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
  });

  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => setCurrentWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const steps = [
    {
      label: 'Dados Básicos',
      icon: Step1Icon,
      mobileIcon: <Step1Icon />,
      step: (
        <Step1
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          updateCourses={setCourses}
          updateHasCourse={setHasCourse}
          updateTracker={setTracker}
          courses={courses}
          hasCourse={hasCourse}
          form={completeForm}
        />
      ),
    },
    {
      label: 'Seleção de Curso',
      icon: Step1ProductIcon,
      mobileIcon: <Step1ProductIcon />,
      step: (
        <Step1Product
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          courses={courses}
          form={completeForm}
        />
      ),
    },
    {
      label: 'Informações Pessoais',
      icon: Step2Icon,
      mobileIcon: <Step2Icon />,
      step: (
        <Step2
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          form={completeForm}
          hasCourse={hasCourse}
        />
      ),
    },
    {
      label: 'Informações Profissionais',
      icon: Step3Icon,
      mobileIcon: <Step3Icon />,
      step: (
        <Step3
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          form={completeForm}
        />
      ),
    },
    {
      label: 'Motivação',
      icon: Step4Icon,
      mobileIcon: <Step4Icon />,
      step: (
        <Step4
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          form={completeForm}
        />
      ),
    },
    {
      label: 'Conhecimentos Específicos',
      icon: Step5Icon,
      mobileIcon: <Step5Icon />,
      step: (
        <Step5
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          form={completeForm}
          tracker={tracker}
        />
      ),
    },
  ];

  return (
    <>
      <MainContainer>
        <HeaderCircled />
        <MainDiv>
          {currentWidth > 768 ? (
            <StyledStepper activeStep={activeStep} alternativeLabel>
              {steps.map((item) => (
                <Step key={item.label}>
                  {/* <StepLabel StepIconComponent={item.icon} onClick={() => {}}>
                    {item.label}
                  </StepLabel> */}
                </Step>
              ))}
            </StyledStepper>
          ) : (
            <MobileStepper>
              {steps[activeStep].mobileIcon}
              <p>{steps[activeStep].label}</p>
            </MobileStepper>
          )}
          {steps[activeStep].step}
        </MainDiv>
      </MainContainer>
      <FooterBlack />
    </>
  );
};

export default ScholarshipAi;
