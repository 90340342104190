import { ReactNode, createContext, useContext, useState } from 'react';

import Toast from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  font-family: 'Radio Canada', sans-serif !important;
  padding: 0 20px !important;
  h1 {
    font-size: 16px;
    margin: 6px;
  }
  p {
    font-size: 14px;
    margin: 6px;
  }
  .MuiAlert-icon {
    font-size: 28px;
    margin-right: 10px;
    align-self: center;
  }
`;

interface IToast {
  type: 'success' | 'error' | 'info';
  title: string;
  description: string;
  time?: number;
  show?: boolean;
}

interface ToastContextData {
  addToast(message: IToast): void;
}

interface ToastProviderProps {
  children: ReactNode;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export function ToastProvider({ children }: ToastProviderProps) {
  const [toast, setToast] = useState<IToast>({} as IToast);

  /**Função que adiciona o toast à tela */
  const addToast = ({
    type,
    title,
    description,
    time = 70000000,
    show = true,
  }: IToast) => {
    setToast({ type, title, description, time, show });
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <Toast
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={toast.time}
        open={toast.show}
        onClose={() =>
          setToast((prev) => {
            return { ...prev, show: false };
          })
        }
      >
        <StyledAlert
          variant="filled"
          severity={toast.type}
          onClose={() =>
            setToast((prev) => {
              return { ...prev, show: false };
            })
          }
        >
          <h1>{toast.title}</h1>
          <p>{toast.description}</p>
        </StyledAlert>
      </Toast>
    </ToastContext.Provider>
  );
}

/** Evita repeticao nos arquivos, pois para usar as funcoes desse
 * contexto nao será nessário declarar "useContext ... etc"
 */
export function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}
