import styled from 'styled-components';
import { Container } from '@mui/material';

export const MainContainer = styled(Container)`
  /* background-color: pink; */
  max-width: max(1250px, 75vw) !important;
  padding: 0 20px !important;
  padding-bottom: 30px;
  min-height: calc(100vh - 125px) !important;

  @media (min-width: 960px) {
    padding: 0 10px !important;
  }

  @media (max-width: 514px) {
    min-height: calc(100vh - 140px) !important;
  }
`;
