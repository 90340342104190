import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const CircularSpinner = styled(CircularProgress)`
  position: fixed !important;
  top: 50% !important;
  color: #7ddd00 !important;
  width: 50px !important;
  height: 50px !important;
`;

export const BlockedDiv = styled.div`
  display: flex !important;
  position: fixed;
  top: 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  width: 100%;
  height: calc(100%);
  // padding-top: -6vw !important;
`;

