import {
  Button,
  Stepper,
  TextField,
  Alert,
  List,
  Container,
  ListItemText,
} from '@mui/material';
import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers';

export const StyledStepper = styled(Stepper)`
  width: min(800px, 90vw) !important;

  & .MuiStepConnector-line {
    border-top-width: 2px;
    margin-top: -1px;
    width: 35px;
    margin: 0 auto;
  }

  & .Mui-completed {
    & .MuiStepConnector-line {
      border-color: #7ddd00;
    }

    border-color: white !important;
    color: white !important;
  }

  & .Mui-active {
    & .MuiStepConnector-line {
      border-color: #7ddd00;
    }

    border-color: #7ddd00 !important;
    color: #7ddd00 !important;
  }

  & .Mui-disabled {
    & .MuiStepConnector-line {
      border-color: rgba(200, 200, 200, 0.35);
    }

    border-color: white;
    color: rgba(200, 200, 200, 0.35);
  }

  & .MuiStepIcon-root {
    height: 45px !important;
    width: 45px !important;
  }

  & .MuiStepLabel-iconContainer {
    transform: scale(1.6);
  }

  span {
    font-family: 'Radio Canada', sans-serif !important;
    font-size: 16px;
  }
`;

export const MobileStepper = styled.div`
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;

  svg {
    color: #7ddd00;
    width: 40px !important;
    height: 40px !important;
  }

  p {
    margin-top: 10px;
    color: #7ddd00;
    font-size: 19px;
    text-align: center;
  }
`;

export const MainForm = styled.form`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  justify-content: space-between;
  align-items: center;
  width: min(900px, 90vw) !important;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
`;

export const LargeInput = styled(TextField)`
  width: min(600px, 90vw) !important;
  height: 48px !important;
  font-family: 'Radio Canada', sans-serif !important;

  & .MuiOutlinedInput-root {
    height: 60px !important;
    color: white !important;
    background-color: black !important;
    font-family: 'Radio Canada', sans-serif !important;

    & fieldset > legend {
      margin-left: 10px !important;
    }

    & fieldset {
      border-width: 1px !important;
      border-color: rgba(200, 200, 200, 0.25) !important;
      font-family: 'Radio Canada', sans-serif !important;
      border-radius: 10px !important;
    }
  }

  & .Mui-focused {
    height: 60px !important;
    color: rgba(255, 255, 255, 0.7) !important;
    font-family: 'Radio Canada', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  & .MuiInputLabel-root {
    height: 20px !important;
  }

  & .MuiSvgIcon-root {
    color: white !important;
    opacity: 0.55 !important;
  }

  & input {
    color: white !important;
    margin-left: 5px !important;
  }

  ::placeholder {
    opacity: 0.55 !important;
  }

  label {
    margin-left: 10px;
  }
`;

export const SmallInput = styled(TextField)`
  min-width: 49% !important;
  height: 48px !important;
  font-family: 'Radio Canada', sans-serif !important;

  & .MuiOutlinedInput-root {
    color: white !important;
    background-color: black !important;
    font-family: 'Radio Canada', sans-serif !important;

    & fieldset > legend {
      margin-left: 10px !important;
    }

    & fieldset {
      border-width: 1px !important;
      border-color: rgba(200, 200, 200, 0.25) !important;
      font-family: 'Radio Canada', sans-serif !important;
      border-radius: 10px !important;
    }
  }

  & .Mui-focused {
    color: rgba(255, 255, 255, 0.7) !important;
    font-family: 'Radio Canada', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  & .MuiInputLabel-root {
    height: 20px !important;
  }

  & .MuiSvgIcon-root {
    color: white !important;
    opacity: 0.55 !important;
  }

  & input {
    color: white !important;
    margin-left: 5px !important;
  }

  ::placeholder {
    opacity: 0.55 !important;
  }

  label {
    margin-left: 10px;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 0px 5px;
  font-family: 'Radio Canada', sans-serif !important;

  @media only screen and (max-width: 700px) {
    padding-right: 0px !important;
    margin-top: 10px;
  }
`;

export const LeftButtonsDiv = styled(ButtonsDiv)`
  flex-direction: row;
`;

export const ButtonsDivRadioForm = styled(ButtonsDiv)`
  width: min(600px, 90vw) !important;
  margin-top: -10px;

  @media only screen and (min-width: 1200px) {
    margin-top: 0;
  }
`;

export const SpecificButtonDiv = styled(ButtonsDivRadioForm)`
  width: min(100%, 90vw) !important;
  margin-right: 50px;
`;

export const ProgressButton = styled(Button)`
  background-color: #7ddd00 !important;
  width: 150px;
  height: 40px;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-family: 'Radio Canada', sans-serif !important;
  /* color: #766f01 !important; */
  color: rgb(18, 18, 18) !important;
  border-radius: 8px !important;

  &:hover {
    background-color: #cbbf00 !important;
  }

  svg {
    height: 16px !important;
  }

  &:disabled {
    background-color: #3f3f3f !important;
    box-shadow: none;
  }

  @media only screen and (min-width: 1200px) {
    height: 50px;
    width: 165px;
  }
`;

export const BackButton = styled(Button)`
  width: 99px;
  height: 40px;
  /* color: #7ddd00 !important; */
  color: rgba(255, 255, 255, 0.8) !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-family: 'Radio Canada', sans-serif !important;
  border-radius: 8px !important;
  border-width: 2px !important;
  border-color: #7ddd00 !important;

  &:hover {
    background-color: #7ddd00 !important;
  }

  @media only screen and (min-width: 1200px) {
    height: 50px;
    width: 105px;
  }
`;

export const StyledAlert = styled(Alert)`
  display: flex !important;
  align-items: center !important;
  width: min(870px, 86vw) !important;
  background-color: rgb(211, 92, 98) !important;
  font-family: 'Radio Canada', sans-serif !important;
  color: white !important;
  font-size: 14px !important;
  margin-left: -10px !important;
  margin-bottom: 10px;
  height: 24px !important;

  svg {
    color: white !important;
  }
`;

export const StyledInputAlert = styled(Alert)`
  display: flex !important;
  align-items: center !important;
  width: min(580px, 84vw) !important;
  background-color: rgb(211, 92, 98) !important;
  font-family: 'Radio Canada', sans-serif !important;
  color: white !important;
  font-size: 13px !important;
  /* margin-left: -10px !important; */
  margin-top: 5px !important;
  margin-bottom: -10px !important;
  height: 26px !important;

  svg {
    color: white !important;
  }

  @media only screen and (min-width: 1200px) {
    height: 24px !important;
    font-size: 14px !important;
  }
`;

export const StyledHeading = styled.h1`
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 25px;
  color: rgba(200, 200, 200);
  display: flex;
  align-self: center;
  text-align: center;
`;

export const StyledHeading2 = styled.h2`
  font-weight: 200;
  font-size: 18px;
  color: rgba(200, 200, 200, 0.8);

  @media (max-width: 1024px) {
  }
`;

export const StyledParagraph = styled.p`
  padding: 1px 20px;
  font-weight: 100;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 0px;
  color: rgba(200, 200, 200, 0.85);

  b {
    font-weight: 600;
  }

  @media (max-width: 1024px) {
    padding: 0px;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  min-width: 49% !important;
  height: 48px !important;
  font-family: 'Radio Canada', sans-serif !important;

  & .MuiOutlinedInput-root {
    color: white !important;
    background-color: black !important;
    font-family: 'Radio Canada', sans-serif !important;

    & fieldset > legend {
      margin-left: 10px !important;
    }

    & fieldset {
      border-width: 1px !important;
      border-color: rgba(200, 200, 200, 0.25) !important;
      font-family: 'Radio Canada', sans-serif !important;
      border-radius: 10px !important;
    }
  }

  & .Mui-focused {
    color: rgba(255, 255, 255, 0.7) !important;
    font-family: 'Radio Canada', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  & .MuiInputLabel-root {
    height: 20px !important;
  }

  & .MuiSvgIcon-root {
    color: white !important;
    opacity: 0.55 !important;
  }

  & input {
    color: white !important;
    margin-left: 5px !important;
  }

  ::placeholder {
    opacity: 0.55 !important;
  }

  label {
    margin-left: 10px;
  }
`;

export const StyledList = styled(List)`
  border: 1px solid;
  border-color: rgba(200, 200, 200, 0.25) !important;
  border-radius: 10px !important;
  width: min(400px, 40vw) !important;
  max-height: 300px;

  & .MuiListItem-root {
    padding: 2px 2px 2px 20px;
  }

  & .MuiTypography-root {
    font-family: 'Radio Canada', sans-serif !important;
  }

  @media only screen and (max-width: 700px) {
    max-height: 400px;
    min-width: 100%;
  }
`;

export const EditButton = styled(Button)`
  width: 50px;
  height: 30px;
  align-self: center;
  /* color: #7ddd00 !important; */
  color: rgba(200, 200, 200, 0.8) !important;
  background-color: rgba(200, 200, 200, 0.1) !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: 'Radio Canada', sans-serif !important;
  border-radius: 8px !important;

  &:hover {
    background-color: rgba(200, 200, 200, 0.7) !important;
    color: rgba(0, 0, 0, 0.8) !important;
    font-weight: 600 !important;
  }
`;

export const StyledContainer = styled(Container)`
  width: 100%;
  display: flex !important;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const StyledTextContainer = styled(StyledContainer)`
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  width: min(650px, 65vw) !important;
  margin-bottom: 10px;

  @media only screen and (max-width: 800px) {
    width: min(90vw) !important;
  }
`;

export const StyledLink = styled.a`
  color: rgba(200, 200, 200, 0.85);
  position: relative !important;
  z-index: 3 !important;
`;

export const StyledListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    color: rgba(200, 200, 200, 0.5);
    font-size: 14px;
  }

  .MuiListItemText-secondary {
    color: rgba(200, 200, 200);
    font-size: 16px;
  }
`;
