import styled from 'styled-components';
import logo from '../../images/logo-faculdade-xp.svg';

const FooterElement = styled.footer`
  // background-color: rgb(55, 55, 55);
  background-color: rgb(18, 18, 18);
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 55px;
  padding: 5px 0;
  margin-top: 60px;

  @media (max-width: 514px) {
    margin-top: 65px;
    height: 55px;
  }
`;

const FooterLogoImg = styled.img`
  height: 35px;
  object-fit: contain;
  margin: 3px 0;
`;

const FooterP = styled.p`
  color: white;
  font-size: 13px;
  height: 15px;
  // background-color: green;
  text-align: center;
  margin: auto;
  line-height: 15px;

  @media (max-width: 514px) {
    height: 30px;
    margin-top: -5px;
  }
`;

const FooterBlack = () => {
  return (
    <FooterElement className={'footer-class'}>
      <FooterLogoImg src={logo} alt="Logo XP Educação" />
      <FooterP>Todos os direitos reservados | Faculdade XP © 2024</FooterP>
    </FooterElement>
  );
};

export default FooterBlack;
